
import { useHelper } from "@react-three/drei";
import { useFBX } from "@react-three/drei";
import React, { useRef } from "react";
import { PointLightHelper } from "three";

export const VRGallery = () => {
  const pointLight = useRef(null);
  useHelper(pointLight, PointLightHelper, 1, 'green');

  const fbx = useFBX('./assets/model/55-rp_nathan_animated_003_walking_fbx/rp_nathan_animated_003_walking.fbx');
  return (
    <scene>
      <ambientLight intensity={1} />
      <pointLight />
      <primitive object={fbx} />;
    </scene>
  );
};