import React from 'react';
import './App.css';
// import { Main } from './components/Main';
import { MainCanvas } from './components/MainCanvas';

export const App = () => {
  return (
    <div className='App'>
      <MainCanvas />
      {/* <Main /> */}
    </div>
  );
};
