import { Environment } from '@react-three/drei';
import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React, { Suspense } from 'react';
import { Loader } from './Loader';
import { VRGallery } from '../scene/vr-gallery/VRGallery';
// import { Space } from './Space';

export const MainCanvas = () => {

  return (
    <Canvas
      style={{ overflow: 'scroll' }}
      className='main-canvas'
      shadows dpr={[1, 2]}
      camera={{ aspect: window.innerWidth / window.innerHeight, position: [150, 150, 150], fov: 100, near: 0.001, far: 1000 }}
      onCreated={(state) => (state.gl.shadowMap.autoUpdate = false)}
    >
      <Suspense fallback={<Loader />}>
        <color attach='background' args={['lightgrey']} />

        <VRGallery />
        {/* <Space /> */}

        <OrbitControls />
        <Environment preset="sunset" background />
      </Suspense>

    </Canvas>
  );
};